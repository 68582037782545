import React, { FC, useState } from "react";
import { useForm } from "react-hook-form";
import {
  CardElement,
  Elements,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { toast } from "react-toastify";
import { Button, InputField } from "../forms";
import { useStateGetter } from "../../hooks/statehooks/UseStateGettersHook";
import { onCreateSubscription, onUpdateUser } from "../../services/user";
import { stripe_PK_live, stripe_PK_test } from "../../utils/URL";
import { loadStripe } from "@stripe/stripe-js";
import { stripelogo } from "../../constants/imports";
import { MdClose } from "react-icons/md";
import { useStateSetter } from "../../hooks/statehooks/UseStateSettersHook";
import { Navigate, useNavigate } from "react-router-dom";

export default ({ show, plan }: any) => {
  const stripePromise = loadStripe(stripe_PK_test);
  const { authuser } = useStateGetter();
  const { setLoading } = useStateSetter();
  const { control } = useForm();
  const CheckoutForm = () => {
    const stripe = useStripe();
const navigate =useNavigate()
    const elements = useElements();

    const handleSubmit = async () => {
      var checkoutdetails = {
        plan: plan,
        date: new Date().toDateString(),
      };

      localStorage.setItem("checkout", JSON.stringify(checkoutdetails));

      if (!stripe || !elements) {
        alert("Stripe has not loaded yet. Please try again.");
        return;
      }

      const cardElement = elements.getElement(CardElement);

      if (!cardElement) {
        alert("Card element is not available.");
        return;
      }

      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: cardElement,
        billing_details: {
          name: authuser.username,
        },
      });
      if (!error) {
        const body = {
          products: [{ name: "Tradetimescanner Premium", plan: plan }],
          name: authuser.username,
          paymentMethod: paymentMethod.id,
          email: authuser.mail,
        };

        setLoading(true);
        try {
          var response = await onCreateSubscription(body);
          const confirm = await stripe.confirmCardPayment(
            response.clientSecret
          );
          if (confirm.error) {
              alert("Payment unsuccessful!");
            navigate("/premiumcanceled")

          } else {
  alert("Payment Successful! Subscription active.");
  var postdata = { data: { subscription_id: response.subscriptionID } };

  var res= await onUpdateUser(postdata, authuser.id)
  
  navigate("/premiumsuccess")
          }
        } catch (e) {
          console.log(e);
        } finally {
          setLoading(false);
          show(false);
        }
      } else {
        alert(error.message);
      }
    };

    return (
      <div className="flex flex-col rounded-lg bg-gray-50 p-3 m-3">
        <img width={"200px"} src={stripelogo} alt="" />

        <div className="flex gap-4 ">
          <InputField
            name="mail"
            title="Email"
            defaultvalue={authuser.mail}
            placeholder="email"
            control={control}
            isDisabled
            rules={{
              required: "is required",
            }}
          />
          <InputField
            name="names"
            title="Names"
            defaultvalue={authuser.username}
            placeholder="name"
            control={control}
            isDisabled
            rules={{
              required: "is required",
            }}
          />
        </div>

        <CardElement className="p-3 m-3 bg-white rounded-lg font-semibold !text-lg !text-gray-700 " />

        <Button disabled={!stripe} text="Subscribe" onBtnClick={handleSubmit} />
      </div>
    );
  };

  return (
    <>
      <div className="ModalContainer shadow ">
        <div className=" flex flex-col  max-h-[70vh] overflow-y-scroll  rounded-[8px] bg-white  w-[90vw] md:w-[40vw] p-4 ">
          <p className="font-semibold self-start text-sm flex justify-between capitalize w-full ">
            <span>
              {" "}
              Complete your {plan} subscription at{" "}
              {plan.includes("monthly") ? "$15" : "$132"}{" "}
            </span>{" "}
            <span>
              {" "}
              <MdClose
                onClick={() => {
                  show(false);
                }}
                size={20}
                className="inline  text-gray-700"
              />
            </span>
          </p>

          <Elements stripe={stripePromise}>
            <CheckoutForm />
          </Elements>
        </div>
      </div>
    </>
  );
};
