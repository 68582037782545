import { useForm } from "react-hook-form";
import { SelectField } from "../../../../../components/forms";
import { ListPicker } from "../../../../../components/generic/ListPicker";
import { useEffect, useState } from "react";
import { usePickerhook } from "../../../../../hooks/usePickerhook";
import Tooltip from "../../../../../components/generic/Tooltip";
import { NavigateBtns } from "../../../../../components/generic/NavigateBtns";
import { useStateSetter } from "../../../../../hooks/statehooks/UseStateSettersHook";
import { useStateGetter } from "../../../../../hooks/statehooks/UseStateGettersHook";

export default ({ nextStep, previousStep }: any) => {
  const marketselecthook = usePickerhook();
  const { setScandetails } = useStateSetter();


  const { control } = useForm();

  
  return (
    <div>
      <div className="border border-1 p-4 mt-4 border-gray-300 rounded">
        <ListPicker
          fullwidth
          hook={marketselecthook}
          title="market"
          list={[{ name: "Forex", code: "forex" },
            {name:"Commodity Markets",code:"Commodity Markets"},
            {name:"Stock Markets",code:" Stock Markets"},
            {name:"OTC (Over-the-Counter)",code:"OTC (Over-the-Counter)"},
            {name:"Index Markets (ETF and Index Funds)",code:"Index Markets (ETF and Index Funds)"},
            {name:"Futures and Options Markets",code:"Futures and Options Markets"},
            {name:"Bond Markets",code:"Bond Markets"},
            {name:"Cryptocurrency Exchanges",code:"Cryptocurrency Exchanges"},
          ]}
          tooltip={
            <Tooltip
              title="Market "
              content="Medium through which assets are traded, enabling buyers and sellers to interact and facilitate exchanges"
            />
          }
        />
      </div>

      <NavigateBtns
        shownext
        actionPrev={() => {
          previousStep();
        }}
        nextCondition={marketselecthook.pickedRole != undefined}
        actionNext={() => {
          setScandetails({ market: marketselecthook.pickedRole.name });
          nextStep();
        }}
      />
    </div>
  );
};
