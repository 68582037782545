import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import StepWizard from "react-step-wizard";
import Progressbar from "../../components/forms/Progressbar";
import Market from "./StepFlow/Market";
import TimeZone from "./StepFlow/TimeZone";
import Scanner from "./StepFlow/Scanner";
import { LiaExchangeAltSolid } from "react-icons/lia";
import { GiRadarSweep } from "react-icons/gi";
import { PiClockFill } from "react-icons/pi";

export default () => {
  const [postdata, setpostdata] = useState({});
  const { element, setpercentage } = Progressbar();
  const [mainActive, setmainActive] = useState<number>(1);

  useEffect(() => {
    setpercentage((mainActive / 3) * 100);
  }, [mainActive]);
  
  const headerindicators = [
    { name: "Market", icon: <LiaExchangeAltSolid className="inline" /> },
    { name: "TimeZone", icon: <PiClockFill className="inline" /> },
    { name: "Scanner", icon: <GiRadarSweep className="inline" /> },
  ];

  useEffect(() => {
    var root = document.getElementsByTagName("div");

    var rootArray = Array.from(root);
    rootArray.forEach((element) =>
      element.scrollTo({ top: 0, behavior: "smooth" })
    );
  }, [mainActive]);

  const IndicatorItem = ({
    icon,
    name,
    key_,
  }: {
    icon: JSX.Element;
    name: string;
    key_: number;
  }) => (
    <div
      className={`${
        mainActive == key_ + 1
          ? "!text-primary font-semibold"
          : " text-gray-400"
      } p-3  `}
    >
      {icon} <span className="ml-1">{name} </span>
    </div>
  );

  return (
    <div className=" h-full  overflow-x-hidden overflow-y-hidden  md:pt-1  w-full flex justify-center items-center-center  ">
      <div className="bg-white rounded h-screen shadow flex flex-col p-4 pt-[4vh]  overflow-y-scroll justify-center items-center  md:pt-1 md:w-[80%]">
        <h1 className="text-lg flex justify-center items-center font-semibold self-center ">
          Step {mainActive} of 3
        </h1>

        <div className="md:w-[60%]">
          <p className="text-sm text-gray-500 my-2">Trade at the right time</p>
          {element}
        </div>
        <div className="w-full flex ">
          {headerindicators.map((item: any, index) => (
            <IndicatorItem icon={item.icon} key_={index} name={item.name} />
          ))}
        </div>
        <StepWizard
          onStepChange={(stepChange: {
            previousStep: number;
            activeStep: number;
          }) => {
            setmainActive(stepChange.activeStep);
          }}
        >
          <Market />
          <TimeZone />
          <Scanner />
        </StepWizard>
      </div>
    </div>
  );
};
