import { loadStripe } from "@stripe/stripe-js";
import { PackList } from "../../components/generic/PackageSelectionmodal";
import { logo } from "../../constants/imports";
import { onCreateCheckout } from "../../services/user";
import { useStateGetter } from "../../hooks/statehooks/UseStateGettersHook";
import usePremiumHook from "../../hooks/usePremiumHook";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export default () => {
  const { authuser } = useStateGetter();
  const { hasaccess } = usePremiumHook();
  const navigate = useNavigate();

  useEffect(() => {
    if (hasaccess) {
      navigate("/premium");
    }

    console.log(hasaccess, "access");
  }, []);
  return (
    <div className=" p-3 bg-white px-10 flex justify-center items-center w-screen h-screen ">
      <div className="flex flex-col gap-4  md:w-[50vw]  w-full ">
        <p className="inline font-semibold ">
          {" "}
          <img onClick={() => {}} src={logo} className=" w-[180px] inline" />
          Get Premium{" "}
        </p>

        <PackList breakdown paid={true} />
      </div>
    </div>
  );
};
