import { loadStripe } from "@stripe/stripe-js";
import { PackList } from "../../components/generic/PackageSelectionmodal";
import { logo } from "../../constants/imports";
import { onCreateCheckout, onUpdateUser } from "../../services/user";
import { useStateGetter } from "../../hooks/statehooks/UseStateGettersHook";
import { IoMdClose } from "react-icons/io";
import { Button } from "../../components/forms";
import { useNavigate } from "react-router-dom";
import { MdCheck } from "react-icons/md";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { useStateSetter } from "../../hooks/statehooks/UseStateSettersHook";

export default () => {
  const { authuser } = useStateGetter();
  const navigate = useNavigate();
  const { setLoading, setAuthuser, setConfig } = useStateSetter();

  const updateUser = async () => {
    var checkoutdetails = JSON.parse(localStorage.getItem("checkout") ?? "{}");
    console.log(checkoutdetails);

    if (checkoutdetails.plan) {
      const expdate = new Date();
      if (checkoutdetails.plan == "yearly") {
        expdate.setFullYear(expdate.getFullYear() + 1);
      } else if (checkoutdetails.plan == "monthly") {
        expdate.setMonth(expdate.getMonth() + 1);
      }
      var postdata = { data: { exp_date: expdate.toISOString() } };

      var res = await onUpdateUser(postdata,authuser.id);

      localStorage.clear()

  if (res.success) {
                toast.success(`Welcome to premium `);
                localStorage.setItem(`UserData`, JSON.stringify(res?.user));
                localStorage.setItem(`AuthToken`, res?.token);
                setAuthuser(res?.user);
                const config = {
                  headers: {
                    Authorization: `Bearer ${res?.token}`,
                  },
                };
                setConfig(config);
              }
    }


    else{

      navigate("/")
    }
  };

  useEffect(() => {
    updateUser();
  }, []);
  return (
    <div className=" p-3 bg-white px-10 flex justify-center items-center w-screen h-screen ">
      <div className="flex flex-col gap-4  md:w-[50vw]  w-full ">
        <p className="inline font-semibold ">
          <img onClick={() => {}} src={logo} className=" w-[180px] " />
          <MdCheck size={30} className="inline text-green-500" />
          Payment Successful{" "}
        </p>

        <Button
          outlined={true}
          text="Go to dashboard"
          onBtnClick={() => {
            navigate("/dashboard");
          }}
        />
      </div>
    </div>
  );
};
