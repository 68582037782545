import { Navigate, Outlet } from "react-router-dom";
import Navbar from "../components/generic/Navbar";
import { Sidebar } from "../components/generic/Sidebar";
import Home from "../pages/home/Home";
import { useMediaQuery } from "react-responsive";
import { useAuth } from "../utils/authentication";

const ProtectedRoute = () => {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 644px)",
  });

  const auth = useAuth();
  return auth ? (
    <div className="w-screen   bg-[#f3f3f3] h-screen flex flex-col  pb-10">
      <Navbar />
      <div className="flex   ">
        {isDesktopOrLaptop && (
          <div className="w-[20vw] z-50  ">
            <Sidebar />
          </div>
        )}
        <div
          className={`overflow-y-scroll h-full !z-[5] ${
            isDesktopOrLaptop ? "w-[80vw]" : "w-[100vw]"
          }`}>  <Outlet />
        </div>
      </div>
    </div>
  ) : (
    <Navigate to="/login" />
  );
};
export default ProtectedRoute;
