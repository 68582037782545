import { useLocation, useNavigate } from "react-router-dom";
import { logolight } from "../../constants/imports";
import { useDispatch } from "react-redux";
import { sidebarItems } from "../../constants/data/data";

export default (props) => {
  var location = useLocation();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  var dispatcher = useDispatch();


  return (
    <div className="bg-customBlack   rounded-[8px] z-[3000000]  min-w-[200px] right-5 absolute top-12 ">
      <div className=" p-4 mt-8">
        {sidebarItems.map((item) => (
          <div
          className={` my-4 p-2 rounded cursor-pointer  text-sm  ${
            pathname.toLowerCase().includes(item.name.toLowerCase()) ? "bg-[#F2F2FD]   font-semibold" : " text-white"
          }`}
          onClick={() => {
            navigate(item.url);
            props.show(false)
          }}
        >
        {item.icon}  {item.name} 
        </div>
    
        ))}
      </div>
    </div>
  );
};
