import { Menu, Transition } from "@headlessui/react";
import { IoIosSettings, IoMdMenu } from "react-icons/io";
import { Fragment } from "react/jsx-runtime";
import { logo } from "../../constants/imports";
import { MdLogout, MdOutlineWorkspacePremium } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { FaUserCircle } from "react-icons/fa";
import { useStateGetter } from "../../hooks/statehooks/UseStateGettersHook";
import { useState } from "react";
import MobileNav from "./MobileNav";
import { useMediaQuery } from "react-responsive";

export default () => {
  var dispatcher = useDispatch();
  var navigate = useNavigate();
  const {authuser} =useStateGetter()
  const [showmobilenav, setshowmobilenav] = useState(false);

  const logout = () => {
    localStorage.clear();
    dispatcher({ type: "set-auth-user", payload: {} });
    navigate("/login");
  };
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 644px)",
  }); 
  return (
    <div className="w-screen shadow  z-[100] h-[10vh]  px-4 bg-white flex justify-between items-center">
      <img  onClick={()=>navigate("/dashboard")} src={logo} className=" w-[180px]" />  

      <div className="flex  justify-center items-center">

       
      <Menu as="div" className="  self-center">
        <div>
          <Menu.Button className=" p-1 flex text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-neutral-400">
            <div className="bg-faint p-2 self-center rounded-xl flex-center items-center">
              <FaUserCircle size={30} className="text-primary sha" />
            </div>
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-sm shadow-md p-1 bg-white ring-1 ring-black ring-opacity-5 py-4 z-[135454545454] focus:outline-none">
            <Menu.Item>
              <div className="p-3 bg-[#000] flex rounded-lg ">
                <div>
                  <img src="/images/avatar.png" alt="" />
                </div>
                <div className="   text-gray-300">
                  <p className="text-sm my-2 font-light ">
                     {authuser && authuser.username}
                  </p>
                  <p className="text-xs font-light mr-2 truncate    ">
                    {authuser && authuser.mail}
                  </p>
                  <p className="text-xs mr-2 text-ellipsis overflow-hidden  font-light  ">
           
                  </p>
                </div>
              </div>
            </Menu.Item>

            
            <hr />
            <Menu.Item>
              {({ active }) => (
                <button
                  className="active:bg-gray-200 block  text-sm rounded-sm p-4  text-gray-700 cursor-pointer focus:bg-gray-200 "
                  onClick={()=>{
navigate('/premium')
                  }}
                >
                  <MdOutlineWorkspacePremium
                  className=" text-[#005A82] inline" /> Premium
                </button>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <button
                  className="active:bg-gray-200 block  text-sm rounded-sm p-4  text-gray-700 cursor-pointer focus:bg-gray-200 "
                  onClick={logout}
                >
                  <MdLogout className=" text-[#005A82] inline" /> Sign out
                </button>
              )}
            </Menu.Item>
          </Menu.Items>
        </Transition>
      </Menu>

   { !isDesktopOrLaptop &&  <IoMdMenu

              onClick={() => {
                setshowmobilenav((prev) => !prev);
              }}
              className="text-[#1E598C]"
              size={30}
            />}
    
      </div>
      {showmobilenav &&<MobileNav show={setshowmobilenav} />}
    </div>
  );
};
