import { Fragment, useEffect, useState } from "react";
import { LiaExchangeAltSolid } from "react-icons/lia";
import { PiClockFill } from "react-icons/pi";
import {
  CiCalendarDate,
  CiCircleInfo,
  CiEdit,
  CiViewTimeline,
} from "react-icons/ci";
import { Menu, Transition } from "@headlessui/react";
import { IoMdEye, IoMdMore } from "react-icons/io";
import { MdDelete } from "react-icons/md";
import { useStateSetter } from "../../hooks/statehooks/UseStateSettersHook";
import { toast } from "react-toastify";
import { onDeleteSaveScan } from "../../services/user";
import { Button } from "../forms";
import ViewDetailsModal from "./ViewDetailsModal";

interface ScanItemDTO {
  pairs?: string[];
  items?: string[];
  details: string[];
}
export default ({ item, refresh }: any) => {
  const [jsonItem, setjsonItem] = useState<ScanItemDTO>();
  const { setLoading } = useStateSetter();
  const [showDetails, setshowDetails] = useState(false);

  const deleteScan = async () => {
    setLoading(true);
    try {
      var res = await onDeleteSaveScan(item.id);
      toast.success("Deleted!");
      refresh();
    } catch (e: any) {
      console.log(e);
      toast.error(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    item.market != "Strategy generator" &&
      setjsonItem(JSON.parse(item.content ? item.content : "{}"));
  }, []);
  return (
    <div className="rounded relative bg-white px-4 flex justify-between  gap-3 py-2 ">
      {showDetails && (
        <ViewDetailsModal item={jsonItem} show={setshowDetails} />
      )}
      {item.market != "Strategy generator" ? (
        <div className="flex flex-col overflow-x-hidden">
          <p className="text-lg font-bold text-gray-700 ">
            <LiaExchangeAltSolid className="inline mx-1" /> {item.market}
            <p className="text-xs grid gap-2 grid-cols-3 md:flex">
              {jsonItem?.pairs?.map((pair: any) => (
                <span className="bg-blue-50 p-1 rounded  font-light truncate">
                  {pair}
                </span>
              ))}
            </p>
            <p className="text-xs grid gap-2 grid-cols-3 md:flex">
              {jsonItem?.items?.map((pair: any) => (
                <span className="bg-blue-50 p-1 rounded font-light truncate ">
                  {pair}
                </span>
              ))}
            </p>
          </p>
          <small className="text-gray-400">
            <CiViewTimeline className="inline text-xs  " />{" "}
            {jsonItem?.pairs?.length} {jsonItem?.items?.length} items
          </small>
          <p className="text-sm font-light text-gray-700">
            <CiCalendarDate className="inline mx-1" />
            {item?.date}
          </p>
          <p className="text-sm font-light text-gray-700">
            <PiClockFill className="inline mx-1" />
            {item?.starttime}
            {" - "} {item?.endtime} {item?.timezone}
          </p>

          <p className=" mt-2 w-full text-xs overflow-hidden text-gray-500 font-light text-justify ">
            <CiCircleInfo className="inline mx-1" />
            {jsonItem?.details[0]}
          </p>

          <Button
            width={200}
            outlined
            text={
              <>
                <IoMdEye className="inline" /> View details
              </>
            }
            onBtnClick={() => {
              setshowDetails(true);
            }}
          />
        </div>
      ) : (
        <div>
          {" "}
          <p className="text-lg font-bold text-gray-700 ">
            <LiaExchangeAltSolid className="inline mx-1" /> {item.market}
          </p>
          <p className="text-sm font-light text-gray-700">
            <CiCalendarDate className="inline mx-1" />
            {item?.date}
          </p>
          <p className="text-sm font-light text-gray-700">
            <PiClockFill className="inline mx-1" />
            Timeframe {item?.starttime}
            {" - "} Tradetime: {item?.endtime}
          </p>
          <div  className="text-gray-500" dangerouslySetInnerHTML={{ __html: item.content }} />
        </div>
      )}

      <Menu as="div" className={` my-2 `}>
        <Menu.Button className="   w-full       ">
          <div className="flex flex-items items-center  w-full gap-4">
            <div className=" flex justify-between w-full  items-center p-1">
              <IoMdMore size={20} className="text-textbg " />
            </div>

            <div className="flex items-center"></div>
          </div>
        </Menu.Button>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items
            onClick={() => {}}
            className="origin-top-left z-10 absolute right-0 mt-2 rounded-sm shadow-md p-1 bg-white w-full  max-h-[200px] max-w-[150px]   overflow-x-hidden overflow-y-scroll ring-1 ring-black ring-opacity-5 focus:outline-none"
          >
            <Menu.Item>
              <p
                onClick={() => {
                  deleteScan();
                }}
                className=" text-xs font-light w-full text-black p-3 m-2 cursor-pointer truncate hover:bg-customGray "
              >
                <MdDelete
                  onClick={() => {
                    console.log("jkj");
                  }}
                  className="inline"
                />{" "}
                Delete
              </p>
            </Menu.Item>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
};
