import { useEffect, useState } from "react";
import Progressbar from "../../components/forms/Progressbar";

import { LiaExchangeAltSolid } from "react-icons/lia";
import { GiRadarSweep } from "react-icons/gi";
import { PiClockFill } from "react-icons/pi";
import PackageSelectionmodal from "../../components/generic/PackageSelectionmodal";
import usePremiumHook from "../../hooks/usePremiumHook";
import { useNavigate } from "react-router-dom";

export default () => {
  const [postdata, setpostdata] = useState({});
  const { element, setpercentage } = Progressbar();
  const [mainActive, setmainActive] = useState<number>(1);
  const navigate = useNavigate();
  const { hasaccess } = usePremiumHook();
  useEffect(() => {
    setpercentage((mainActive / 3) * 100);
  }, [mainActive]);
  const headerindicators = [
    { name: "Market", icon: <LiaExchangeAltSolid className="inline" /> },
    { name: "TimeZone", icon: <PiClockFill className="inline" /> },
    { name: "Scanner", icon: <GiRadarSweep className="inline" /> },
  ];

  const IndicatorItem = ({
    icon,
    name,
    key_,
  }: {
    icon: JSX.Element;
    name: string;
    key_: number;
  }) => (
    <div
      className={`${
        mainActive == key_ + 1
          ? "!text-primary font-semibold"
          : " text-gray-400"
      } p-3  `}
    >
      {icon} <span className="ml-1">{name} </span>
    </div>
  );

  return (
    <div className="h-screen flex justify-center items-start  overflow-x-hidden overflow-y-scroll  ">
      {!hasaccess && (
        <PackageSelectionmodal
          show={(state:boolean) => {
            navigate("/dashboard");
          }}
        />
      )}

      <iframe
      className="h-screen w-full"
        src="https://tradetimescanner.com/indicator_test.html"
        width={"100%"}
        height={"100%"}
      />
    </div>
  );
};
