import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ProtectedRoute from "./ProtectedRoutes";
import { Login } from "../pages/auth/Login";
import { RegisterAccount } from "../pages/auth/Signup";
import Home from "../pages/home/Home";
import Scanner from "../pages/home/Scanner";
import AllScans from "../pages/home/AllScans";
import { Reset } from "../pages/auth/reset";
import Analysis from "../pages/home/Analysis";
import StrategyGen from "../pages/home/StrategyGen";
import { Verify } from "../pages/auth/verify";
import Premium from "../pages/home/Premium";
import GetPremium from "../pages/home/GetPremium";
import GetPremiumCanceled from "../pages/home/GetPremiumCanceled";
 import GetPremiumSuccess from "../pages/home/GetPremiumSuccess";
import { ResetPass } from "../pages/auth/Changepasword";

export default () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<ProtectedRoute />}>
          <Route path="" element={<Home />} />
          <Route path="dashboard" element={<Home />} />
          <Route path="allscans" element={<AllScans />} />
          <Route path="scanner" element={<Scanner />} />
          <Route path="strategy" element={<StrategyGen />} />
          <Route path="trades" element={<Scanner />} />
          <Route path="analysis" element={<Analysis />} />
          <Route path="premium" element={<Premium />} />
        </Route>
        <Route path="getpremium" element={<GetPremium />} />
        <Route path="premiumcanceled" element={<GetPremiumCanceled />} />
        <Route path="premiumsuccess" element={<GetPremiumSuccess />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<RegisterAccount />} />
        <Route path="/reset" element={<Reset />} />
        <Route path="/verify/:id" element={<Verify />} />
        <Route path="/resetpass/:id" element={<ResetPass />} />
      </Routes>
    </Router>
  );
};
